button,
[role="button"] {
  font: var(--font);
  box-sizing: border-box;
  border: var(--button-border);
  border-color: var(--button-border-color);
  border-radius: var(--border-radius);
  box-shadow: var(--button-shadow);
  color: #222;
  min-width: 75px;
  min-height: 23px;
  padding: 0 12px;
  text-align: center;
  background: var(--button-gradient);
  position: relative;
  z-index: 0;

  /* Button style on hovered */
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    border-radius: var(--border-radius);
    box-shadow: var(--button-shadow);
    background: var(--button-gradient-hovered);
    opacity: 0;
    transition: opacity 0.3s;
    z-index: -1;
  }

  /* Button style on clicked */
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    box-shadow: var(--button-shadow-active);
    border-radius: 2px;
    background: var(--button-gradient-active);
    opacity: 0;
    transition: opacity 0.3s;
    z-index: -1;
  }

  &:disabled {
    background: var(--button-face-disabled);
    border-color: var(--button-border-color-disabled);
    color: var(--button-text-color-disabled);
  }

  &:not(:disabled) {
    /* Animation when hovered */
    &:hover {
      border-color: var(--button-border-color-hovered);
      transition: border-color 0.3s;

      &::before {
        opacity: 1;
        transition: opacity 0.3s;
      }
    }

    /* Animation when unhovered */
    &:not(:hover) {
      border-color: var(--button-border-color);
      transition: border-color 1s linear;

      &::before {
        opacity: 0;
        transition: opacity 1s linear;
      }
    }

    &:active,
    &.active {
      border-color: var(--button-border-color-active);
      transition: border-color 0.3s;

      &::after {
        opacity: 1;
        transition: opacity 0.3s;
      }
    }
  }

  &:focus-visible,
  &.focused {
    box-shadow: inset 0 0 0 2px var(--button-shade-light-active);
    outline: 1px dotted #000;
    outline-offset: -4px;
  }

  &.default,
  &:focus,
  &.focused {
    border-color: var(--button-border-color-default);
    background-image: var(--button-gradient-hovered);
    animation: 1s ease infinite alternate pulse-anim;
  }
}

@keyframes pulse-anim {
  from {
    box-shadow: inset 0 0 3px 1px #34deffdd;
  }
  to {
    box-shadow: inset 0 0 1px 1px #34deffdd;
  }
}
