:root {
  --chevron-size: 5px;
}

details {
  margin-top: 0;

  > summary {
    display: inline;
    position: relative;
    margin-bottom: 0;
    cursor: pointer;

    &:before {
      content: "";
      position: absolute;
      top: calc(50% - var(--chevron-size));
      right: 100%;
      border: var(--chevron-size) solid transparent;
      border-left-color: #000;
      border-radius: 3px;
    }

    &::-webkit-details-marker,
    &::marker {
      display: none;
    }
  }

  &[open] {
    > summary {
      &:before {
        top: calc(50% - var(--chevron-size) / 2);
        transform: rotateZ(45deg);
      }
    }
  }
}
