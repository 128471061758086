:root {
  --link-color: #0066cc;
  --link-color-hovered: #3399ff;
  --primary-color: #003399;
  --secondary-color: #000000;
}

a {
  color: var(--link-color);
  text-decoration: none;

  &:focus-visible {
    outline: 1px dotted var(--link-color);
  }

  &:hover,
  &:focus {
    color: var(--link-color-hovered);
    text-decoration: underline;
  }
}

.instruction {
  font: var(--font);
  color: var(--secondary-color);
  font-weight: normal;
  margin: 0 0 20px;

  &-primary {
    font-size: 12pt;
    color: var(--primary-color);
  }
}

.header {
  font: var(--font);
  font-weight: normal;

  &-document {
    font-family: "Calibri", "Noto Sans", sans-serif;
    font-size: 17pt;
    color: var(--secondary-color);
  }

  &-group {
    font-size: 11pt;
    color: var(--primary-color);
  }
}
