:root {
  --combobox-chevron-size: 4px;
}

.combobox {
  position: relative;
  display: inline-block;

  input[type="text"] {
    padding-right: 20px;
    width: 100%;
  }

  button {
    position: absolute;
    right: 0;
    padding: 0;
    min-width: 16px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background: url("icon/button-down.svg") center no-repeat,
      var(--button-gradient);

    &::before {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background: url("icon/button-down.svg") center no-repeat,
        var(--button-gradient-hovered);
    }

    &::after {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background: url("icon/button-down.svg") center no-repeat,
        var(--button-gradient-active);
    }

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
}
