:root {
  --window-spacing: 6px;
  --window-border: 1px solid;
  --window-border-radius: 6px;
  --window-border-color: #000000b3;
  --window-background-color: #4580c4;
  --window-background-glass-stripes: linear-gradient(
      135deg,
      #fff5 70px,
      transparent 100px
    ),
    linear-gradient(225deg, #fff5 70px, transparent 100px),
    linear-gradient(
        54deg,
        /* ------------------------------- 1st stripe ------------------------------- */
          #0002 0 4%,
        #6661 6% 6%,
        #0002 8% 10%,
        /* ------------------------------- 2nd stripe ------------------------------- */
          #0002 15% 16%,
        #aaa1 17% 18%,
        #0002 23% 24%,
        /* ------------------------------- 3rd stripe ------------------------------- */
          #bbb2 25% 26%,
        #0002 31% 33%,
        /* ------------------------------- 4th stripe ------------------------------- */
          #0002 34% 34.5%,
        #bbb2 36% 40%,
        /* ------------------------------- 5th stripe ------------------------------- */
          #0002 41% 41.5%,
        #bbb2 44% 45%,
        /* ------------------------------- 6th stripe ------------------------------- */
          #bbb2 46% 47%,
        #0002 48% 49%,
        #0002 50% 50.5%,
        /* ------------------------------- 7th stripe ------------------------------- */
          #0002 56% 56.5%,
        #bbb2 57% 63%,
        #0002 67% 69%,
        /* ------------------------------- 8th stripe ------------------------------- */
          #bbb2 69.5% 70%,
        #0002 73.5% 74%,
        /* ------------------------------- 9th stripe ------------------------------- */
          #bbb2 74.5% 79%,
        #0002 80% 84%,
        /* ------------------------------- 10th stripe ------------------------------- */
          #aaa2 85% 86%,
        #0002 87%,
        #bbb1 90%
      )
      left center/100vw 100vh no-repeat fixed;
  --window-background: linear-gradient(
      to right,
      #ffffff66,
      #0000001a,
      #ffffff33
    ),
    var(--window-background-color);

  --control-border-color: #0000004d;
  --control-border-radius: 5px;
  --control-inset-shadow: inset 0 0 0 1px #fffa;
  --control-background: linear-gradient(
    #ffffff80,
    #ffffff4d 45%,
    #0000001a 50%,
    #0000001a 75%,
    #ffffff80
  );

  /* Colors for minimize, maximize, and restore buttons when hovered */
  --control-background-hovered: radial-gradient(
      circle at bottom,
      #2aceda,
      transparent 65%
    ),
    linear-gradient(#b6d9ee 50%, #1a6ca1 50%);

  /* Colors for minimize, maximize, and restore buttons when clicked */
  --control-background-active: radial-gradient(
      circle at bottom,
      #0bfdfa,
      transparent 65%
    ),
    linear-gradient(#86a7bc 50%, #092747 50%);

  /* Colors for the close button at normal state */
  --control-background-close: radial-gradient(
      circle at -60% 50%,
      #0007 5% 10%,
      #0000 50%
    ),
    radial-gradient(circle at 160% 50%, #0007 5% 10%, #0000 50%),
    linear-gradient(#e0a197e5, #cf796a 25% 50%, #d54f36 50%);

  /* Colors for the close button when hovered */
  --control-background-close-hovered: radial-gradient(
      circle at 50% 170%,
      #f4e676 10% 20%,
      #0000 60%
    ),
    radial-gradient(circle at -60% 50%, #000a 5% 10%, #0000 50%),
    radial-gradient(circle at 160% 50%, #000a 5% 10%, #0000 50%),
    linear-gradient(#fb9d8b, #ee6d56 25% 50%, #d42809 50%);

  /* Colors for the close button when clicked */
  --control-background-close-active: radial-gradient(
      circle at 50% 170%,
      #dcc03f 10% 20%,
      #0000 60%
    ),
    radial-gradient(circle at -60% 50%, #000 5% 10%, #0000 50%),
    radial-gradient(circle at 160% 50%, #000 5% 10%, #0000 50%),
    linear-gradient(#d1a894, #b67562 25% 50%, #7d0d01 50%);
}

.window {
  font: var(--font);
  box-shadow: 2px 2px 10px 1px var(--window-border-color), inset 0 0 0 1px #fffa;
  border: var(--window-border) var(--window-border-color);
  border-radius: var(--window-border-radius);
  position: relative;
  z-index: 0;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--window-border-radius);
    background: linear-gradient(transparent 20%, #ffffffb3 40%, transparent 41%),
      var(--window-background);
    background-color: var(--window-background-color);
    box-shadow: inset 0 0 0 1px #fffd;
  }

  &-body {
    margin: var(--window-spacing);
    margin-top: 0;
    border: var(--window-border) var(--window-border-color);
    background: var(--surface);
    box-shadow: 0 0 0 1px #fff9;

    &.has-space {
      padding: var(--window-spacing);
    }

    pre {
      margin: calc(var(--window-spacing) * -1);
    }
  }

  &-footer,
  footer {
    margin: var(--window-spacing);
    margin-top: calc(-1 * var(--window-spacing) - 1px);
    background: var(--surface);
    border: var(--window-border) var(--window-border-color);
    border-top: 0;
    box-shadow: 0 0.5px 1px 0.5px #fff;
    position: relative;
    padding: 10px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 2px;
      box-shadow: inset 0 1px #0000004d, inset 0 -1px #fff;
    }
  }

  &.is-bright {
    .window-body {
      background: #fff;
    }

    .window-footer,
    footer {
      background: #eee;
      box-shadow: inset 0 1px 3px #ddd, 0 1px 0 #fff9, 1px 1px 0 #fff9,
        -1px 1px 0 #fff9;

      &::before {
        content: none;
      }
    }
  }

  &.glass {
    &::before {
      opacity: 0.6;
      /* filter: brightness(1.2); */
    }

    &::after {
      content: "";
      position: absolute;
      z-index: -10;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: var(--window-border-radius);
      border: none;
      backdrop-filter: blur(4px);
    }

    > .title-bar {
      background: var(--window-background-glass-stripes);
    }
  }

  > .title-bar {
    border: 0;
    box-shadow: inset 0 1px 0 #fffd, inset 1px 0 0 #fffd, inset -1px 0 0 #fffd;
  }

  fieldset {
    margin-bottom: 9px;
  }

  &[role="dialog"] {
    position: fixed;
    z-index: 999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    visibility: hidden;
    opacity: 0;
    transition-duration: 0.2s;
    transition-property: visibility, opacity;

    &:target {
      visibility: visible;
      opacity: 1;
    }
  }
}

.title-bar {
  font: var(--font);
  box-shadow: inset 0 0 0 1px #fff9;
  border: var(--window-border) var(--window-border-color);
  border-radius: var(--window-border-radius) var(--window-border-radius) 0 0;
  padding: var(--window-spacing);
  padding-top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--window-background);
  background-color: var(--window-background-color);

  &-text {
    color: #000;
    letter-spacing: 0;
    line-height: 15px;
    padding-top: var(--window-spacing);
    text-shadow: 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff,
      0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff;
  }

  &-controls {
    display: flex;
    background: #fff3;
    border: var(--window-border) var(--control-border-color);
    border-top: 0;
    border-radius: 0 0 var(--control-border-radius) var(--control-border-radius);
    box-shadow: 0 1px 0 #fffa, 1px 0 0 #fffa, -1px 0 0 #fffa;

    button {
      position: relative;
      min-width: 29px;
      min-height: 19px;
      padding: 0;
      border: 0;
      border-right: var(--window-border) var(--control-border-color);
      border-radius: 0;
      box-shadow: none;
      box-sizing: border-box;
      background: none;

      &::after {
        content: none;
      }

      &:hover,
      &:active {
        /* resolve the conflict with button styles */
        background: none;
      }

      &:disabled {
        &::before {
          opacity: 0.4;
        }
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 0;
        box-shadow: inset 0 0 0 1px #fff5;
        opacity: 1;
      }

      &:not(:hover)::before {
        transition: none;
        opacity: 1;
      }

      &[aria-label="Minimize"],
      &.is-minimize {
        &::before {
          background: url("./icon/minimize.png") no-repeat center 10px;
        }
      }

      &[aria-label="Maximize"],
      &.is-maximize {
        &::before {
          background: url("./icon/maximize.png") no-repeat center;
        }
      }

      &[aria-label="Help"],
      &.is-help {
        &::before {
          background: url("./icon/help.png") no-repeat center;
        }
      }

      &[aria-label="Restore"],
      &.is-restore {
        &::before {
          background: url("./icon/restore.png") no-repeat center;
        }
      }

      &[aria-label="Close"],
      &.is-close {
        min-width: 48px;

        &::before {
          background: url("./icon/close.png") no-repeat center;
        }
      }

      &:first-child,
      &:first-child::before {
        border-bottom-left-radius: var(--control-border-radius);
      }

      &:last-child,
      &:last-child::before {
        border: 0;
        border-bottom-right-radius: var(--control-border-radius);
      }

      &:focus {
        outline: none;
        animation: none;
      }
    }
  }

  /* Active title-bar */
  .window.active &,
  &.active {
    .title-bar-controls {
      border-color: var(--window-border-color);

      button {
        border-color: var(--window-border-color);
        box-shadow: var(--control-inset-shadow);

        &::after {
          content: none;
        }

        &[aria-label="Minimize"],
        &.is-minimize {
          background: url("./icon/minimize.png") no-repeat center 10px,
            var(--control-background);
          &::before {
            content: "";
            box-shadow: 0 0 7px 3px #5dc4f0, var(--control-inset-shadow);
            background: url("./icon/minimize.png") no-repeat center 10px,
              var(--control-background-hovered);
            border-bottom-left-radius: var(--control-border-radius);
            opacity: 0;
            transition: opacity 0.3s linear;
          }

          &:hover::before,
          &:focus-visible::before {
            opacity: 1;
            transition: opacity 0.1s linear;
          }

          &:active::before {
            background: url("./icon/minimize.png") no-repeat center 10px,
              var(--control-background-active);
          }
        }

        &[aria-label="Maximize"],
        &.is-maximize {
          background: url("./icon/maximize.png") no-repeat center,
            var(--control-background);
          &::before {
            content: "";
            box-shadow: 0 0 7px 3px #5dc4f0, var(--control-inset-shadow);
            background: url("./icon/maximize.png") no-repeat center,
              var(--control-background-hovered);
            opacity: 0;
            transition: opacity 0.3s linear;
          }

          &:hover::before,
          &:focus-visible::before {
            opacity: 1;
            transition: opacity 0.1s linear;
          }

          &:active::before {
            background: url("./icon/maximize.png") no-repeat center,
              var(--control-background-active);
          }
        }

        &[aria-label="Help"],
        &.is-help {
          background: url("./icon/help.png") no-repeat center,
            var(--control-background);
          &::before {
            content: "";
            box-shadow: 0 0 7px 3px #5dc4f0, var(--control-inset-shadow);
            background: url("./icon/help.png") no-repeat center,
              var(--control-background-hovered);
            opacity: 0;
            transition: opacity 0.3s linear;
          }

          &:hover::before,
          &:focus-visible::before {
            opacity: 1;
            transition: opacity 0.1s linear;
          }

          &:active::before {
            background: url("./icon/help.png") no-repeat center,
              var(--control-background-active);
          }
        }

        &[aria-label="Restore"],
        &.is-restore {
          background: url("./icon/restore.png") no-repeat center,
            var(--control-background);
          &::before {
            content: "";
            box-shadow: 0 0 7px 3px #5dc4f0, var(--control-inset-shadow);
            background: url("./icon/restore.png") no-repeat center,
              var(--control-background-hovered);
            opacity: 0;
            transition: opacity 0.3s linear;
          }

          &:hover::before,
          &:focus-visible::before {
            opacity: 1;
            transition: opacity 0.1s linear;
          }

          &:active::before {
            background: url("./icon/restore.png") no-repeat center,
              var(--control-background-active);
          }
        }

        &[aria-label="Close"],
        &.is-close {
          background: url("./icon/close.png") no-repeat center,
            var(--control-background), var(--control-background-close);
          box-shadow: var(--control-inset-shadow);

          &::before {
            content: "";
            background: url("./icon/close.png") no-repeat center,
              var(--control-background), var(--control-background-close-hovered);
            box-shadow: 0 0 7px 3px #e68e75, var(--control-inset-shadow);
            border-bottom-right-radius: var(--control-border-radius);
            opacity: 0;
            transition: opacity 0.3s linear;
          }

          &:hover::before,
          &:focus-visible::before {
            opacity: 1;
            transition: opacity 0.1s linear;
          }

          &:active::before {
            background: url("./icon/close.png") no-repeat center,
              var(--control-background), var(--control-background-close-active);
          }
        }

        &:disabled {
          &[aria-label="Maximize"],
          &.is-maximize {
            background: var(--control-background);

            &::before {
              content: "";
              background: url("./icon/maximize.png") no-repeat center;
              opacity: 0.4;
            }
          }
          /* TODO: Add rules to style disabled close button */
        }
      }
    }
  }
}

.status-bar {
  margin: var(--window-spacing);
  margin-top: calc(var(--window-spacing) * -1);
  background: var(--surface);
  border: var(--window-border) var(--window-border-color);
  border-top: 0;
  box-shadow: 0 1px 0 #fff9, 1px 0 0 #fff9, -1px 0 0 #fff9;
  display: flex;

  &-field {
    border-right: var(--window-border) #cfcfcf;
    padding: 2px 3px;
    margin: 0;
    flex-grow: 1;

    &:last-child {
      border-right: 0;
    }
  }
}
