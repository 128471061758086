fieldset {
  border: 1px solid #cdd7db;
  border-radius: var(--border-radius);
  box-shadow: inset 0 0 0 1px #fff;
  padding: calc(2 * var(--border-width) + var(--element-spacing));
  padding-block-start: var(--element-spacing);
  margin: 0;

  legend {
    font: var(--font);
  }
}

.field-row {
  display: flex;
  align-items: center;

  > * {
    + * {
      margin-left: var(--grouped-element-spacing);
    }
  }

  &-stacked {
    display: flex;
    flex-direction: column;

    * {
      + * {
        margin-top: var(--grouped-element-spacing);
      }
    }
  }
}

[class^="field-row"] {
  + [class^="field-row"] {
    margin-top: var(--grouped-element-spacing);
  }
}
