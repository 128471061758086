.spinner,
.loader {
  background: url("./icon/spinner.png") center;
  width: 20px;
  height: 20px;
  display: inline-block;
  color: transparent;

  &.animate {
    background-image: url("./icon/spinner.gif");
  }
}
