:root {
  --checkbox-width: 14px;
  --checkbox-label-spacing: 6px;
  --checkbox-total-width-precalc: 0;
  --checkbox-total-width: calc(var(--checkbox-total-width-precalc));
  --checkmark-top: 0;
  --checkmark-left: 2px;
}

input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font: var(--font);
  background: none;
  border: none;
  margin: 0;
  opacity: 0;

  + label {
    font: var(--font);
    display: inline-flex;
    align-items: center;
    position: relative;
    margin-left: var(--checkbox-total-width);

    &::before {
      content: "";
      display: inline-block;
      width: var(--checkbox-width);
      height: var(--checkbox-width);
      margin-right: var(--checkbox-label-spacing);
      background: #f6f6f6;
      border: 1px solid;
      border-color: var(--button-border-color);
      box-shadow: inset 0 0 0 1px #f4f4f4, inset 1px 1px 0 1px #aeaeae,
        inset -1px -1px 0 1px #ddd, inset 3px 3px 6px #ccc;
      box-sizing: border-box;
      transition: 0.4s;
    }

    &:hover::before {
      background: #e9f7fe;
      border-color: var(--button-border-color-hovered);
      box-shadow: inset 0 0 0 1px #def9fa, inset 1px 1px 0 1px #79c6f9,
        inset -1px -1px 0 1px #c6e9fc, inset 3px 3px 6px #b1dffd;
    }
  }

  &:focus-visible {
    + label {
      outline: 1px dotted #000;
    }
  }

  &:checked {
    + label {
      &::after {
        content: "\2714";
        font-weight: bold;
        display: block;
        position: absolute;
        top: var(--checkmark-top);
        left: var(--checkmark-left);
        color: #4a5f97;
      }
    }
  }

  &:disabled {
    + label {
      opacity: 0.6;
    }
  }
}
