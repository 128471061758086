select:not([multiple]) {
  font: var(--font);
  padding: 2px 3px;
  border: var(--button-border);
  border-color: var(--button-border-color);
  border-radius: var(--border-radius);
  box-shadow: var(--button-shadow);
  box-sizing: border-box;
  color: #222;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  padding-right: 30px;
  background: url("./icon/button-down.svg"), var(--button-gradient);
  background-position: center right;
  background-repeat: no-repeat;

  &:hover {
    border-color: var(--button-border-color-hovered);
    background-image: url("./icon/button-down.svg"),
      var(--button-gradient-hovered);
  }

  &:active {
    outline: none;
    border-color: var(--button-border-color-active);
    box-shadow: var(--button-shadow-active);
    background-image: url("./icon/button-down.svg"),
      var(--button-gradient-active);
  }

  &:focus {
    outline: 1px dotted #000;
    outline-offset: -4px;
    box-shadow: inset 0 0 0 2px var(--button-shade-light-active);
  }
}
