:root {
  --tab-border: 1px solid #888;
  --tab-border-color: #888;
  --tab-bg: #fff;
}

[role="tabpanel"] {
  position: relative;
  z-index: 2;
}

menu[role="tablist"] {
  position: relative;
  margin: 0 0 -2px 0;
  text-indent: 0;
  list-style-type: none;
  display: flex;
  padding-left: 3px;

  button {
    padding: 2px 6px;
    border-radius: 0;
    z-index: 1;
    display: block;
    color: #222;
    text-decoration: none;
    min-width: unset;

    &[aria-selected="true"] {
      padding-bottom: 4px;
      margin: -2px 0px 1px -3px;
      background: var(--tab-bg);
      box-shadow: none;
      border-bottom: 0;
      position: relative;
      z-index: 8;

      &::before,
      &::after {
        content: none;
      }

      &:hover {
        border-color: var(--tab-border-color);
      }

      &:focus,
      &:active,
      &.active {
        border-color: var(--tab-border-color);
        animation: none;
      }

      &:focus-visible {
        outline: 1px dotted #222;
        outline-offset: -4px;
      }
    }

    &::before {
      border-radius: 0;
    }

    &::after {
      content: none;
    }

    &:disabled {
      opacity: 0.6;
    }
  }

  &.justified {
    button {
      flex-grow: 1;
      text-align: center;
    }
  }
}

[role="tabpanel"] {
  padding: 14px;
  clear: both;
  background: var(--tab-bg);
  border: var(--tab-border);
  position: relative;
  z-index: 2;
  margin-bottom: 9px;
}
