:root {
  --menu-padding: 2px;
  --item-offset-left: 30px;
}

ul {
  &[role] {
    margin: 0;
    padding: 0;
    list-style: none;
    cursor: default;
  }

  &[role="menubar"] {
    display: flex;
    background: linear-gradient(
      #fff 20%,
      #f1f4fa 25%,
      #f1f4fa 43%,
      #d4dbee 48%,
      #e6eaf6
    );

    > [role="menuitem"] {
      position: relative;
      padding: 6px 10px;

      &:focus,
      &:focus-within,
      &:hover {
        background: #3399ff;
        color: #fff;
        outline: none;
        ~ [role="menuitem"]:focus,
        ~ [role="menuitem"]:focus-within {
          background: transparent;
          color: inherit;
        }
        &:has(~ [role="menuitem"]:hover) {
          background: transparent;
          color: inherit;
        }
      }
    }
  }

  &[role="menu"] {
    position: relative;
    min-width: 150px;
    padding: var(--menu-padding);
    background: var(--surface);
    color: initial;
    border: 1px solid #0006;
    box-shadow: 4px 4px 3px -2px #00000080;

    &::before {
      content: "";
      pointer-events: none;
      position: absolute;
      left: var(--item-offset-left);
      width: 2px;
      height: calc(100% - 4px);
      box-shadow: inset 1px 0 #00000026, inset -1px 0 #fff;
    }

    [role="menuitem"] & {
      display: none;
      position: absolute;
      left: 0;
      top: 100%;
      z-index: 99;
    }

    & [role="menuitem"] > [role="menu"] {
      top: -4px;
      left: 100%;
    }

    > [role="menuitem"] {
      > a,
      > button,
      > label,
      &[aria-haspopup="true"] {
        all: unset;
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 4px 10px 4px 32px;
        box-sizing: border-box;
        border: 1px solid transparent;
        border-radius: 3px;
        white-space: nowrap;

        &:hover,
        &:focus-visible {
          background: var(--item-highlighted-background);
          border-color: var(--item-highlighted-border);
        }
      }

      > button {
        &:hover::before,
        &::after {
          content: none;
        }
      }

      &[aria-haspopup="true"] {
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          right: 2px;
          transform: translateY(-50%);
          border: 4px solid transparent;
          border-left-color: currentColor;
        }
      }
    }
  }

  [role="menuitem"] {
    position: relative;

    > input[type] {
      display: none;

      & + label {
        display: block;
        position: relative;

        &::before {
          all: unset; /* unset the standalone checkbox/radio styles */
          background: var(--item-highlighted-background);
          box-shadow: 0 0 0 1px #b3d3f9;
          border-radius: inherit;
          box-sizing: border-box;
          position: absolute;
          top: 0;
          left: 0;
          width: 22px;
          height: 22px;
        }
      }

      &:checked + label {
        &::before {
          content: "";
        }
      }

      &[type="radio"]:checked + label {
        &::after {
          left: 8px;
          top: 50%;
          transform: translateY(-50%);
          background: radial-gradient(circle at 75% 25%, #d5d4ea, #333583);
          border: 1px solid #1a1490;
          box-shadow: none;
        }
      }

      &[type="checkbox"]:checked + label {
        &::after {
          color: #0c12a1;
          font-size: 10pt;
          left: 6px;
          top: 50%;
          transform: translateY(-52%);
        }
      }
    }

    &:focus,
    &:focus-within {
      > [role="menu"] {
        display: block;
      }
      &:has(~ [role="menuitem"]:hover) {
        > [role="menu"] {
          display: none;
        }
      }
    }

    &[aria-disabled] {
      opacity: 0.5;
      pointer-events: none;
    }

    &.has-divider {
      &::after {
        content: "";
        pointer-events: none;
        display: block;
        margin: 3px 0 2px;
        height: 2px;
        margin-left: var(--item-offset-left);
        box-shadow: inset 0 1px #00000026, inset 0 -1px #fff;
      }
    }

    img {
      position: absolute;
      z-index: 1;
      left: 2px;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
    }

    span {
      margin-left: 32px;
    }
  }

  &.can-hover [role="menuitem"]:hover {
    > [role="menu"] {
      display: block;
    }
    ~ [role="menuitem"]:focus,
    ~ [role="menuitem"]:focus-within {
      background: transparent;
      color: inherit;
      > [role="menu"] {
        display: none;
      }
    }
  }
}
