body {
  font-family: "Segoe UI", "SegoeUI", "Noto Sans", sans-serif;
  font-size: 9pt;
  color: #222222;
}

.surface {
  background: var(--surface);
}

h1 {
  font-size: 5rem;
}

h2 {
  font-size: 2.5rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.5rem;
}

u {
  text-decoration: none;
  border-bottom: 0.5px solid #222222;
}

code {
  &,
  * {
    font-family: monospace;
  }
}

pre {
  display: block;
  margin: 0;
  padding: 12px 8px;
  background: #fff;
  border: 1px solid var(--button-border-color);
}

summary {
  &:focus-visible {
    outline: 1px dotted #000;
  }
}
